import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {  FaChevronDown, FaUser } from "react-icons/fa";
import { FlagIcon } from "react-flag-kit";
import { useTranslation } from "react-i18next";
import { BaseUrl } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth.slice";
import {
  DashboardBrowsingIcon,
  Logout01Icon,
  Settings01Icon
} from "hugeicons-react";
import $ from "jquery";

export default function TopBar() {
  const { t, i18n } = useTranslation();
  // const [userData, setUserData] = useState({ firstName: "", lastName: "" });
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLngsa") || i18n.language
  );
  // const [isOffline, setIsOffline] = useState(!navigator.onLine);
  // const navigate = useNavigate();
  const { user} = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logout());
    window.location.reload();
  };

  const toggleLanguageMenu = () => {
    setShowLanguageMenu(!showLanguageMenu);
  };

  const handleLanguageChange = async (lng) => {
    // console.log(lng)
    // console.log(i18n.hasResourceBundle(lng, 'translation'))
    if (!i18n.hasResourceBundle(lng, "translation")) {
      try {
        await i18n.loadLanguages(lng); // Charger la langue dynamiquement
      } catch (error) {
        console.error("Erreur lors du chargement de la langue :", error);
      }
    }
    i18n.changeLanguage(lng); // Changer la langue après le chargement
    setLanguage(lng);
    setShowLanguageMenu(false);
    // window.location.href = "/a";
    $(document).ready(function(){
      window.location.href = "/a";
      // window.location.href("/a");
  });
    // setLanguage(lng)
    // i18n.changeLanguage(lng);
  };

  const getFlag = (lang) => {
    switch (lang) {
      case "fr":
        return <FlagIcon code="FR" size={16} />;
      case "de":
        return <FlagIcon code="DE" size={16} />;
      case "es":
        return <FlagIcon code="ES" size={16} />;
      case "dk":
        return <FlagIcon code="DK" size={16} />;
      case "nl":
        return <FlagIcon code="NL" size={16} />;
      default:
        return <FlagIcon code="GB" size={16} />;
    }
  };
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-tiers max-lg:hidden z-[150] flex w-full justify-end">
      <div className="flex gap-1 items-center py-1.5">
        <div className="flex">
          {user ? (
            <div className="relative inline-block text-left">
              <button
                onClick={() => setOpen(!open)}
                className="text-[.8rem] cursor-pointer border-r border-gray-600 font-semibold flex gap-2 items-center px-4"
              >
                <img
                  src={BaseUrl + "/" + user.profile}
                  className="w-6 h-6 object-cover rounded-[50px]"
                  alt="ccca_profile"
                />{" "}
                {user.username}
              </button>
              {open && (
                <div className="absolute z-[1000] right-0 text-[.9rem] mt-2 w-52 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden">
                  <ul className="py-2 text-gray-800">
                    <li className="">
                      <a
                        href="/admin"
                        className="px-5 py-3 hover:bg-gray-100 cursor-pointer flex items-center gap-3 transition-all"
                      >
                        <DashboardBrowsingIcon className="w-4 h-4 text-gray-600" />{" "}
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="px-5 py-3 hover:bg-gray-100 cursor-pointer flex items-center gap-3 transition-all"
                      >
                        <Settings01Icon className="w-4 h-4 text-gray-600" />{" "}
                        Paramètres
                      </a>
                    </li>
                    <li
                      onClick={handleLogout}
                      className="px-5 py-3 hover:bg-gray-100 cursor-pointer flex items-center gap-3 transition-all text-red-600 border-t border-gray-200"
                    >
                      <Logout01Icon className="w-4 h-4" /> Déconnexion
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <Link
              to="/login"
              className="cursor-pointer text-[.7rem] px-4 border-x-2 border-gray-300"
            >
              {t("login")}
            </Link>
          )}
        </div>
        <div className="relative">
          <button
            onClick={toggleLanguageMenu}
            className="text-[.7rem] px-4 py-2.5 flex items-center gap-1 cursor-pointer"
            title={t("changeLanguage")}
          >
            {getFlag(language)} <FaChevronDown />
          </button>
          {showLanguageMenu && (
            <div className="absolute right-0 mt-2 z-[1000] bg-white border border-gray-300 rounded shadow-lg w-40">
              <div
                onClick={(e) => handleLanguageChange("fr")}
                className="px-4 py-2 text-[.7rem] cursor-pointer hover:bg-gray-100 flex items-center gap-2"
              >
                <FlagIcon code="FR" size={16} /> Français
              </div>
              <div
                onClick={(e) => handleLanguageChange("en")}
                className="px-4 py-2 text-[.7rem] cursor-pointer hover:bg-gray-100 flex items-center gap-2"
              >
                <FlagIcon code="GB" size={16} /> English
              </div>
              {/* Add more languages here */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
