import React from "react";
import { A11y, Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import img1 from "../assets/1x/IMG-20241028-WA0027.jpg";
import img2 from "../assets/1x/IMG-20241028-WA0026.jpg";
import img3 from "../assets/1x/IMG-20241028-WA0028.jpg";
import img4 from "../assets/programmes/alterans.jpeg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Cancel01Icon } from 'hugeicons-react';

export default function Publicite({ h = 310 }) {
  const data = [img1, img2, img3];

  const data1 = [img4];
  const { t } = useTranslation();

  const Publication1 = () => {
    return (
      <div className="flex flex-col gap-5">
        {/* <div className='bg-white absolute cursor-pointer right-[3%] top-[3%] text-secondary flex flex-col items-center justify-center w-[25px] h-[25px] rounded-[50px]'><Cancel01Icon size={'15px'} fontSize={'800'}/></div> */}
        <span className="text-white font-bold text-[1rem] max-md:text-[.9rem]">
          {t("pub.descrip")}
        </span>
        <div className="w-full bg-white h-fit">
          <Swiper
            modules={[Scrollbar, A11y, Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false
            }}
            spaceBetween={15}
            slidesPerView={1}
            // effect='fade'
          >
            {data?.map((x, index) => (
              <SwiperSlide key={index} className="bg-gray-100">
                <NavLink
                  className="flex items-center justify-center px-1 py-1 bg-gray-100 rounded-sm"
                  to={"http://franchise-it-tech.com/"}
                >
                  <img
                    className={`object-cover w-full h-[${h}px] max-md:h-[330px] max-sm:h-[250px]`}
                    src={x}
                    alt=""
                  />
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  };
  const Publication2 = () => {
    return (
      <div className="flex flex-col gap-5">
        {/* <div className='bg-white absolute cursor-pointer right-[3%] top-[3%] text-secondary flex flex-col items-center justify-center w-[25px] h-[25px] rounded-[50px]'><Cancel01Icon size={'15px'} fontSize={'800'}/></div> */}
        <span className="text-white font-bold text-[1rem] max-md:text-[.9rem]">
          Nourrir le Monde Naturellement !
        </span>
        <div className="w-full bg-white h-fit">
          <Swiper
            modules={[Scrollbar, A11y, Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false
            }}
            spaceBetween={15}
            slidesPerView={1}
            // effect='fade'
          >
            {data1?.map((x, index) => (
              <SwiperSlide key={index} className="bg-gray-100">
                <NavLink
                  className="flex items-center justify-center px-1 py-1 bg-gray-100 rounded-sm"
                  to={"https://www.linkedin.com/posts/alterans-foods-beverages_alteransfoods-madeincameroon-pimentfraissecetenpoudre-activity-7289571538322808833-ajer?utm_source=share&utm_medium=member_ios&rcm=ACoAABkZCGcBzuYG-MBu5MqPt33wrdarSpNFH4A"}
                >
                  <img
                    className={`object-cover w-full h-[${h}px] max-md:h-[330px] max-sm:h-[250px]`}
                    src={x}
                    alt=""
                  />
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  };
  return (
    <div>
      <h5 className="uppercase text-[0.7rem] mb-3 font-light">
        {t("pub.name")}
      </h5>
      <div className="bg-secondary relative px-[7.5%] max-md:px-[6%] flex flex-col gap-5 py-[2rem] rounded-md">
        <Swiper
          modules={[Scrollbar, A11y, Autoplay]}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
            waitForTransition:true
          }}
          spaceBetween={15}
          slidesPerView={1}
        >
          <SwiperSlide>
            <Publication1 />
          </SwiperSlide>
          <SwiperSlide>
          <Publication2 />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
