import axios from 'axios';
import { BaseUrl } from '../config';

const API_URL = BaseUrl +'/';

// Register user
const register = async userData => {
  const response = await axios.post (API_URL + 'register.php', userData);
  return response.data;
};


// Login user
const login = async (userData) => {
  const response = await axios.post (API_URL + 'login.php', userData);

  if (response.data) {
    localStorage.setItem ('user', JSON.stringify (response.data.data));
    // localStorage.setItem ('token', JSON.stringify (response.data.token));
  }

  // console.log(response)
  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem ('user');
  // localStorage.removeItem ('token');
};

const authService = {
  register,
  logout,
  login,
};

export default authService;
