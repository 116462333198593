import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/1x/icone.png';
import banner from '../../assets/footer.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth.slice';


export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [isForgotPassword, setIsForgotPassword] = useState(false); // New state to toggle form view
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user,isSuccess,message, isLoading,isError } = useSelector(
    (state) => state.auth
  ); 

  useEffect(() => {
    if(isSuccess){
      navigate('/a')
    }
    
  }, [navigate,isSuccess]);


  const handleLogin = async (e) => {
    e.preventDefault()
    const datas={email,password}
    if(email!=='' && password!==''){
      await dispatch(login(datas))
    }else{
      alert('donnée manquante')
    }
    if(isError){
      alert(message)
    }
  };
// console.log(email,password)

  
  return (
    <div
      className='flex items-center justify-center min-h-screen bg-gray-100'
      style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backdropFilter: 'blur(10px)',
      }}
    >
      <img className='absolute w-[90%] h-[90%] -left-70 max-md:left-0 opacity-50 object-contain' src={logo} alt='' />
      <div className='absolute w-full h-full z-2 gradient'></div>
      <div className='absolute max-md:w-[95%] bg-white bg-opacity-80 py-12 px-[4%] max-md:px-[7%] rounded-[3px] shadow-lg w-full max-w-md flex flex-col items-center justify-center gap-2'>

        <h2 className='mb-4 text-xl font-bold text-center'>
          {isForgotPassword ? 'Mot de passe oublié' : 'Connexion'}
        </h2>
        {error && <p className='mb-4 text-sm text-red-500'>{error}</p>}

        {isForgotPassword ?
          // Password Reset Form
          <form onSubmit={(e) => { e.preventDefault() }} className='w-full space-y-4'>
            <div>
              <input
                type='email'
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className='w-full px-1.5 py-2 border pl-5 rounded-[3px] text-sm focus:outline-none focus:ring focus:ring-blue-300'
                placeholder='Votre email'
              />
            </div>
            <button
              type='submit'
              className={`w-full text-white py-2 rounded-[3px] ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'}`}
              disabled={loading}
            >
              {loading ? (
                <span className="loader"></span>
              ) : (
                'Envoyer le lien de réinitialisation'
              )}
            </button>
          </form>
          :
          // Login Form
          <>
            <form onSubmit={handleLogin} className='w-full space-y-4'>
              <div>
                <input
                  type='text'
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className='w-full px-1.5 py-3 border pl-5 rounded-[3px] text-sm focus:outline-none focus:ring focus:ring-blue-300'
                  placeholder="Votre nom d'utilisateur ou email"
                />
              </div>
              <div>
                <input
                  type='password'
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className='w-full px-1.5 py-3 border pl-5 rounded-[3px] text-sm focus:outline-none focus:ring focus:ring-blue-300'
                  placeholder='Votre mot de passe'
                />
              </div>
              <button
                type='submit'
                className={`w-full text-white py-3 rounded-[3px] ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="loader"></span>
                ) : (
                  'Se connecter'
                )}
              </button>
            </form>

            <p className='w-full mt-4 text-sm text-gray-600' style={{ textAlign: 'right' }}>
              <button
                className='text-blue-500 hover:underline'
                disabled={loading}
              >
                Mot de passe oublié?
              </button>

            </p>
            <p className='mt-4 text-sm text-center text-gray-600'>
              <Link to='/a' className='text-blue-500 hover:underline' style={{ fontWeight: 'bold' }}>
                Retour à l'Accueil
              </Link>
            </p>
          </>}
        <style jsx>{`
        .loader {
          border: 3px solid #f3f3f3;
          border-top: 3px solid #3498db;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          animation: spin 0.8s linear infinite;
          display: inline-block;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
      </div>
      </div>
      );
}
