import React from "react";
import Banner from "../../components/Banner";
import Infolettre from "../../components/Infolettre";
import { ArrowRight01Icon, Mail01Icon, TelephoneIcon } from "hugeicons-react";
import { A11y, Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import cteq from "../../assets/1x/cteq.webp";
import quebec from "../../assets/1x/quebec.png";
import pn from "../../assets/1x/pn.svg";
import chambers from "../../assets/1x/chambers.avif";
import fga from "../../assets/1x/fga.svg";
import fit from "../../assets/fit.png";
import hec from "../../assets/hec.svg";
import ifpf from "../../assets/ifpf.png";
import imgr from "../../assets/1x/imigra.jpg";
import bannersMembre from "../../assets/2149241214.jpg";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import Annimated from "../../components/Annimated";
import Publicite from "../../components/Publicite";
import jcc from '../../assets/1x/jcck.jpeg';
import gpf from '../../assets/1x/gpf.png';

export default function DevenirMembre() {
  const data = [
    {
      link: "https://ctequebec.com/",
      image: cteq
    },
    {
      link: "https://www.quebecinternational.ca/",
      image: quebec
    },
    {
      link: "https://www.printempsnumerique.ca/",
      image: pn
    },
    {
      link: "https://www.immigrationcast.ca/",
      image: imgr
    },
    {
      link: "https://canadaafrica.ca/",
      image: chambers
    },
    {
      link: "https://fedefranco.ca/",
      image: fga
    },
    {
      link: "http://franchise-it-tech.com/",
      image: fit
    },
    {
      link: "https://www.hec.ca/",
      image: hec
    },
    {
      link: "http://franchise-it-tech.com/",
      image: ifpf
    },
    { link: 'https://jcckq-rdc.com/', image: jcc },
    { link: 'https://www.gpf-int.org/', image: gpf }
  ];

  const { t } = useTranslation();

  const state = [
    { num: "+2000", libele: t("stats.adherent1") },
    { num: "+500", libele: t("stats.membres") },
    { num: "+25", libele: t("stats.decideurs") },
    { num: "+10", libele: t("stats.activites") },
    { num: "+50", libele: t("stats.experts") }
  ];

  const membre = [
    {
      title: t("stats.adherent.elargirSonReseau.title"),
      option: [
        t("stats.adherent.elargirSonReseau.option.0"),
        t("stats.adherent.elargirSonReseau.option.1"),
        t("stats.adherent.elargirSonReseau.option.2"),
        t("stats.adherent.elargirSonReseau.option.3"),
        t("stats.adherent.elargirSonReseau.option.4"),
        t("stats.adherent.elargirSonReseau.option.5"),
        t("stats.adherent.elargirSonReseau.option.6"),
        t("stats.adherent.elargirSonReseau.option.7")
      ]
    },
    {
      title: t("stats.adherent.apprendreEtSavoir.title"),
      option: [
        t("stats.adherent.apprendreEtSavoir.option.0"),
        t("stats.adherent.apprendreEtSavoir.option.1"),
        t("stats.adherent.apprendreEtSavoir.option.2"),
        t("stats.adherent.apprendreEtSavoir.option.3"),
        t("stats.adherent.apprendreEtSavoir.option.4"),
        t("stats.adherent.apprendreEtSavoir.option.5"),
        t("stats.adherent.apprendreEtSavoir.option.6")
      ]
    },
    {
      title: t("stats.adherent.obtenirVisibilite.title"),
      option: [
        t("stats.adherent.obtenirVisibilite.option.0"),
        t("stats.adherent.obtenirVisibilite.option.1"),
        t("stats.adherent.obtenirVisibilite.option.2"),
        t("stats.adherent.obtenirVisibilite.option.3"),
        t("stats.adherent.obtenirVisibilite.option.4"),
        t("stats.adherent.obtenirVisibilite.option.5"),
        t("stats.adherent.obtenirVisibilite.option.6")
      ]
    },
    {
      title: t("stats.adherent.croitreInternational.title"),
      option: [
        t("stats.adherent.croitreInternational.option.0"),
        t("stats.adherent.croitreInternational.option.1"),
        t("stats.adherent.croitreInternational.option.2"),
        t("stats.adherent.croitreInternational.option.3"),
        t("stats.adherent.croitreInternational.option.4"),
        t("stats.adherent.croitreInternational.option.5"),
        t("stats.adherent.croitreInternational.option.6")
      ]
    }
  ];

  $(function () {
    let toggleValue = false;
    $(document).on("click", ".settings-btn", function () {
      toggleValue = !toggleValue;
      const pixel = $(this).siblings().prop("scrollHeight");
      if (toggleValue) {
        $(this).siblings().css("height", `${pixel}px`);
        $(this).children().last().addClass("rotate-[90deg]");
      } else {
        $(this).siblings().css("height", `0px`);
        $(this).children().last().removeClass("rotate-[90deg]");
      }
    });
  });

  const tables = t("tables", { returnObjects: true });

  // console.log(tables);
  const Tableau = ({ item }) => {
    return (
      <div className="flex border-[1px] max-md:w-[900px] border-primary last-of-type:mb-1 mb-7">
        <div className="p-2 w-[20%] flex items-center justify-center border-r-[1px] border-primary">
          {item.title}
        </div>
        <div className="p-3 w-[40%] flex flex-col items-center justify-center border-r-[1px] border-primary">
          <h6>{item?.descrip?.libelle}</h6>
          <ul className="list-disc ps-5">
            {item?.descrip?.option?.map((x, indexe) => (
              <li key={indexe}>{x}</li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-[15%] border-r-[1px] border-primary">
          <div className="flex items-center justify-center p-3 h-1/2">
            Canada
          </div>
          <div className="p-3 h-1/2 flex items-center justify-center border-t-[1px] border-primary">
            Afrique
          </div>
        </div>
        <div className="flex flex-col w-[15%] border-r-[1px] border-primary">
          <div className="flex items-center justify-center p-3 h-1/2">
            {item.price.can}
            {item.price.can !== "Gratuit" ? ",00 $CAD" : ""}
          </div>
          <div className="p-3 h-1/2 flex items-center justify-center border-t-[1px] border-primary">
            {item.price.af}
            {item.price.af !== "Gratuit" ? ",00 $CAD" : ""}
          </div>
        </div>
        <div className="w-[10%] text-green-600">
          <a
            href={item.link.can}
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center p-3 hover:text-secondary h-1/2"
          >
            {" "}
            {t("stats.souscrire")}
          </a>
          <a
            href={item.link.af}
            target="_blank"
            rel="noreferrer"
            className="hover:text-secondary p-3 h-1/2 flex items-center justify-center border-t-[1px] border-primary"
          >
            {t("stats.souscrire")}
          </a>
        </div>
      </div>
    );
  };
  return (
    <Annimated>
      <Banner title={t("becomeMember")} />
      <div className="px-[5%] w-full flex gap-[3rem] py-14 max-md:py-12 ">
        <div className="w-[73%] max-md:w-full flex flex-col gap-12 max-md:px-0">
          <div className="flex flex-col gap-2">
            <img
              className="w-full h-[150px] object-cover"
              src={bannersMembre}
              alt="#"
            />
            {/* <button onClick={()=>setOpens(true)} className='w-1/3 px-6 py-3 text-white max-md:w-full bg-secondary'>Devenir Membre</button> */}
          </div>
          <div className="flex flex-col">
            <h1 className="font-bold uppercase text-[1.3rem] max-md:text-[1.2rem] text-secondary py-4 border-b-2 border-secondary">
              {t("chooseCategory")}
            </h1>
            <div className="flex flex-col w-full">
              {tables?.map((x, index) => (
                <div
                  key={index}
                  className="flex flex-col overflow-hidden border-b-2 border-secondary"
                >
                  <div className="settings-btn cursor-pointer py-4 w-full text-[1.1rem] max-md:text-[0.9rem] font-semibold flex justify-between items-center uppercase px-4 bg-gray-100 text-primary">
                    <span className="">{x.type}</span>
                    <ArrowRight01Icon className="font-bold transition-all duration-500" />
                  </div>
                  <div
                    className="overflow-x-auto overflow-y-hidden transition-all duration-500"
                    style={{ height: 0 }}
                  >
                    {x?.detail?.map((y, ind) => (
                      <Tableau key={ind} item={y} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h1 className="text-[1.3rem] max-md:text-[1.2rem] font-bold">
              {" "}
              {t("chamberInfo")}
            </h1>
            <div className="grid grid-cols-5 gap-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-md:gap-3">
              {state?.map((x, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center gap-1 p-6 text-white rounded-sm bg-primary"
                >
                  <span className="font-bold text-[1.5rem]">{x.num}</span>
                  <p className="font-medium text-[.9rem]">{x.libele}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h1 className="text-[1.3rem] max-md:text-[1.2rem] font-bold">
              {" "}
              {t("whyBecomeMember")}
            </h1>
            <div className="grid grid-cols-2 gap-10 max-md:grid-cols-1">
              {membre?.map((x, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-5 p-6 px-8 py-8 border-2 rounded-sm border-primary"
                >
                  <h1 className="font-bold underline  text-primary text-[1.5rem]">
                    {x.title}
                  </h1>
                  <ul className="list-disc ps-[1rem] text-[.95rem]">
                    {x.option?.map((y, ind) => (
                      <li key={ind}>{y}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[27%] flex flex-col gap-10 max-md:hidden">
          <div className="flex flex-col gap-3">
            <h5 className="uppercase text-[0.7rem] mb-0 font-light">
              {t("moreInfo")}
            </h5>
            <div className="bg-primary px-8 flex flex-col gap-2 py-[1rem]">
              <span className="text-white">{t("phone")}</span>
              <div className="font-semibold bg-gray-100 px-4 py-3 rounded-sm flex item-center gap-3 text-[1rem]">
                <TelephoneIcon />
                +1 (438) 388-3606
              </div>
            </div>
            <div className="bg-primary px-8 flex flex-col gap-2 py-[1rem]">
              <span className="text-white">{t("email")}</span>
              <div className="font-semibold bg-gray-100 px-4 py-3 rounded-sm flex item-center gap-3 text-[1rem]">
                <Mail01Icon />
                info@cc-ca.ca
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h5 className="uppercase text-[0.7rem] mb-0 font-light">
              {t("partners")}
            </h5>
            <div className="px-6 py-4 bg-gray-100">
              <div className="flex items-center gap-3">
                <Swiper
                  modules={[Scrollbar, A11y, Autoplay]}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                  }}
                  spaceBetween={15}
                  slidesPerView={1}
                  // effect='fade'
                >
                  {data?.map((x) => (
                    <SwiperSlide className="bg-gray-100">
                      <NavLink
                        className="flex items-center justify-center px-2 py-1 bg-gray-100 rounded-sm"
                        to={x.link}
                      >
                        <img className=" h-[65px]" src={x.image} alt="" />
                      </NavLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
          <Publicite />
          <Infolettre />
        </div>
      </div>
    </Annimated>
  );
}
