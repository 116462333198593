import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/effect-fade';
import jambo from '../../assets/jambotv.PNG';
// import pad from '../../assets/1x/page.jpg';
import actual from '../../assets/actual1.jpg';
import actual2 from '../../assets/actu2.avif';
import actual3 from '../../assets/actual2.jpg';
import actual4 from '../../assets/programmes/actual.jpeg';
import actual5 from '../../assets/programmes/actual2.jpeg';
import actual6 from '../../assets/programmes/actual3.jpeg';
import actual7 from '../../assets/programmes/actual4.jpeg';
import actual8 from '../../assets/programmes/actual5.jpeg';
import actual9 from '../../assets/programmes/actual6.jpeg';
import actual10 from '../../assets/programmes/actuall7.jpeg';
import actual11 from '../../assets/programmes/actual81.jpeg';
import actual12 from '../../assets/programmes/actual_canal2.png';
import actual13 from '../../assets/programmes/imgVideo1.png';
import actual14 from '../../assets/programmes/imgVideo2.png';
import actual15 from '../../assets/programmes/imgVideo3.png';
import actual16 from '../../assets/programmes/signature.png';
import actual17 from '../../assets/programmes/semmaine.png';
import actual18 from '../../assets/programmes/actuali.jpeg';
import actual19 from '../../assets/programmes/actual191.jpeg';
import vidJambo from '../../assets/videos/Publier.mp4';
import Banner from '../../components/Banner';
import Infolettre from '../../components/Infolettre';
import Annimated from '../../components/Annimated';
import { ArrowRight01Icon, ArrowLeft01Icon } from 'hugeicons-react';
import Publicite from '../../components/Publicite';
import { timeRegular } from '../../components/acceuil/Services';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActualite } from '../../store/actualites.slice';
import { BaseUrl } from "../../config";
// import moment from 'moment';

export default function Actualites() {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7; 
    const dispatch = useDispatch();
  const { Actualites, isSuccess, isLoading,isError } = useSelector(
    (state) => state.actualites
  ); 

//   const [globalData,setGlobalData]=useState([])
// let globalData=[]
  useEffect(() => {
    dispatch(getAllActualite());
  }, [dispatch, isSuccess,Actualites]);

//   console.log(Actualites)
const data = [
    {
        id:20,
        images: [actual19],
        type: 'interne',
        title: "𝗥𝗲𝗻𝗰𝗼𝗻𝘁𝗿𝗲 𝗮𝘂 𝘀𝗼𝗺𝗺𝗲𝘁 𝗲𝗻𝘁𝗿𝗲 𝗹𝗮 𝗖𝗖𝗖𝗔 𝗲𝘁 𝗹𝗮 𝗥𝗲́𝗽𝘂𝗯𝗹𝗶𝗾𝘂𝗲 𝗖𝗲𝗻𝘁𝗿𝗮𝗳𝗿𝗶𝗰𝗮𝗶𝗻𝗲 🇨🇫🤝🇨🇦",
        description: "Notre #Ambassadrice des 𝗛𝗮𝘂𝘁𝗲𝘀 𝗔𝗳𝗳𝗮𝗶𝗿𝗲𝘀 pour la Chambre de Commerce Canada Afrique, Mme valerie nougoum tchedjou a eu l’honneur de rencontrer Son Excellence, le Professeur Faustin-Archange Touadéra, 𝗣𝗿𝗲́𝘀𝗶𝗱𝗲𝗻𝘁 𝗱𝗲 𝗹𝗮 𝗥𝗲́𝗽𝘂𝗯𝗹𝗶𝗾𝘂𝗲 𝗱𝗲 𝗖𝗲𝗻𝘁𝗿𝗮𝗳𝗿𝗶𝗾𝘂𝗲.",
        created_at: '2025-03-11T17:01:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 19,
        images: [actual18],
        type: 'interne',
        title: "Échange entre le Président de la CCCA et son Excellence Madame Loraine Anderson Haute Commissaire du Canada au Cameroun",
        description: "𝙎𝙤𝙣 𝙀𝙭𝙘𝙚𝙡𝙡𝙚𝙣𝙘𝙚 𝙈𝙖𝙙𝙖𝙢𝙚 Lorraine Anderson , 𝐇𝐚𝐮𝐭𝐞 𝐂𝐨𝐦𝐦𝐢𝐬𝐬𝐚𝐢𝐫𝐞 𝐃𝐮 𝐂𝐚𝐧𝐚𝐝𝐚 𝐀𝐮 𝐂𝐚𝐦𝐞𝐫𝐨𝐮𝐧, de passage à Douala, capitale économique du Cameroun, a accordé un échange privilégié au 𝐏𝐫é𝐬𝐢𝐝𝐞𝐧𝐭 𝐃𝐞 𝐋𝐚 Chambre de Commerce Canada Afrique, le Dr. Armand NGAKETCHA NJAFANG.",
        created_at: '2025-02-20T15:46:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 18,
        images: [actual17],
        type: 'interne',
        title: "3ième Édition de la SEMAINE CANADIENNE en Republique Démocratique du Congo (RDC)",
        description: "Intervention du Président de la Chambre de Commerce Canada Afrique lors de la 3ième édition de la SEMAINE CANADIENNE en RDC",
        created_at: '2025-02-19T15:30:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 17,
        images: [actual16],
        type: 'interne',
        title: "𝐒𝐢𝐠𝐧𝐚𝐭𝐮𝐫𝐞 𝐎𝐟𝐟𝐢𝐜𝐢𝐞𝐥𝐥𝐞 𝐃𝐮 𝐏𝐚𝐫𝐭𝐞𝐧𝐚𝐫𝐢𝐚𝐭 𝐂𝐜𝐜𝐚 & 𝐓𝐮𝐫𝐜𝐚𝐛𝐚 : 𝐔𝐧𝐞 𝐀𝐥𝐥𝐢𝐚𝐧𝐜𝐞 𝐒𝐭𝐫𝐚𝐭é𝐠𝐢𝐪𝐮𝐞 𝐏𝐨𝐮𝐫 𝐋’𝐚𝐯𝐞𝐧𝐢𝐫 𝐃𝐮 𝐂𝐨𝐦𝐦𝐞𝐫𝐜𝐞 𝐂𝐚𝐧𝐚𝐝𝐚-𝐭𝐮𝐫𝐪𝐮𝐢𝐞-𝐚𝐟𝐫𝐢𝐪𝐮𝐞 🇨🇦🇹🇷🇨🇲",
        description: "Le 3 février 2025, à Yaoundé, le Dr. Armand Ngaketcha Njafang, Président de la Chambre de Commerce Canada-Afrique (CCCA), et M. Fabrice Funiba, Président de la Chambre de Commerce Turco-Camerounaise (TURCABA) ...",
        date: '2025-02-05T08:00:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 16,
        images: [actual15],
        type: 'interne',
        title: "ÉXONÉRATIONS DOUANIÈRES",
        description: "Troisième Volet – Trois Axes Majeurs Pour Une Réponse Stratégique : 1️⃣ Projet d’Exonération Douanière : Faciliter l’entrée des produits canadiens sur les marchés africains et, en réciprocité, offrir un accès privilégié aux produits africains au Canada.",
        created_at: '2025-02-04T12:00:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 15,
        images: [actual14],
        type: 'interne',
        title: "ÉNONCÉ DE POLITIQUE GÉNÉRALE DE LA CCCA",
        description: "Deuxième Volet – La CCCA Active Ses Leviers Pour Contrer L'Impact Des 25% De Tarifs Douaniers Imposés Par Les États-Unis Dans cette seconde intervention, le Président de la Chambre de Commerce Canada-Afrique, Dr. Armand Ngaketcha Njafang, dévoile les initiatives et stratégies concrètes que la CCCA mettra en place pour accompagner les entreprises, renforcer les investissements et sécuriser le commerce international.",
        created_at: '2025-02-04T12:00:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 14,
        images: [actual13],
        type: 'interne',
        title: "BÂTIR NOS RELATIONS D'AFFAIRES SUR LE COMMERCE ET L'INVESTISSEMENT",
        description: "Face à la ratification de 25% des tarifs douaniers imposés aux états-unis, la CCCA se voit répondre par une proposition à travers l’énoncé de politique générale 2025. ",
        created_at: '2025-02-04T12:00:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 13,
        images: [actual12],
        type: 'interne',
        title: "Interview du Président de la Chambre de Commerce Canada Afrique sur l'émission *L'Invité De La Semaine* de Canal2",
        description: "📢 𝐋𝐄 𝐂𝐀𝐍𝐀𝐃𝐀, 𝐋𝐄 𝐍𝐎𝐔𝐕𝐄𝐋 𝐄𝐋𝐃𝐎𝐑𝐀𝐃𝐎 ? 🌍💼. La question de 𝗹’𝗶𝗺𝗺𝗶𝗴𝗿𝗮𝘁𝗶𝗼𝗻 𝘃𝗲𝗿𝘀 𝗹𝗲 𝗖𝗮𝗻𝗮𝗱𝗮 suscite de plus en plus d’intérêt mais aussi d’inquiétudes pour le 𝗚𝗼𝘂𝘃𝗲𝗿𝗻𝗲𝗺𝗲𝗻𝘁 𝗰𝗮𝗺𝗲𝗿𝗼𝘂𝗻𝗮𝗶𝘀 qui voit fuir sa main d’œuvre. Pourtant, une autre forme d’immigration par 𝗹’𝗘𝗻𝘁𝗿𝗲𝗽𝗿𝗲𝗻𝗲𝘂𝗿𝗶𝗮𝘁, le 𝗥𝗲𝗽𝗿𝗲𝗻𝗲𝘂𝗿𝗶𝗮𝘁 et 𝗹’𝗜𝗻𝘃𝗲𝘀𝘁𝗶𝘀𝘀𝗲𝗺𝗲𝗻𝘁 est très peu connue et mutuellement bénéfique pour les deux parties : l’Immigration d’Affaires. Le Président de la Chambre de Commerce Canada Afrique (La CCCA, Le Dr. Armand NGAKETCHA NJAFANG était l’Invité de la Semaine sur Canal 2 International pour aborder ce sujet crucial.",
        created_at: '2025-02-04T12:00:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 12,
        images: [actual11],
        type: 'interne',
        title: "𝐕𝐞𝐫𝐬 𝐔𝐧𝐞 𝐂𝐨𝐥𝐥𝐚𝐛𝐨𝐫𝐚𝐭𝐢𝐨𝐧 𝐒𝐭𝐫𝐚𝐭é𝐠𝐢𝐪𝐮𝐞 𝐄𝐧 𝐑𝐝𝐜 🇨🇩 𝐗 🌍",
        description: "La Chambre de Commerce Canada-Afrique (CCCA) poursuit son déploiement en République Démocratique du Congo avec des avancées significatives. La semaine dernière, notre Délégué pour l’Est de la RDC, Laurent Lokwa, a été reçu par la Chambre de Commerce Internationale de la RDC pour poser les bases d'une collaboration stratégique.",
        created_at: '2025-02-03T09:00:00Z',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }, 
    {
        id: 11,
        images: [actual9],
        type: 'interne',
        title: "𝙐𝙣 𝙥𝙖𝙧𝙩𝙚𝙣𝙖𝙧𝙞𝙖𝙩 𝙚𝙣 𝙘𝙤𝙣𝙨𝙩𝙧𝙪𝙘𝙩𝙞𝙤𝙣 𝙚𝙣𝙩𝙧𝙚 𝙡𝙖 𝘾𝙝𝙖𝙢𝙗𝙧𝙚 𝙙𝙚 𝘾𝙤𝙢𝙢𝙚𝙧𝙘𝙚 𝘾𝙖𝙣𝙖𝙙𝙖-𝘼𝙛𝙧𝙞𝙦𝙪𝙚 (𝘾𝘾𝘾𝘼) 𝙚𝙩 𝙡𝙖 𝘾𝙝𝙖𝙢𝙗𝙧𝙚 𝙙𝙚 𝘾𝙤𝙢𝙢𝙚𝙧𝙘𝙚 𝘾𝙖𝙢𝙚𝙧𝙤𝙪𝙣𝙤-𝙏𝙪𝙧𝙦𝙪𝙚 (𝙏𝙐𝙍𝘾𝘼𝘽𝘼)",
        description: "Hier, Dr. Armand NGAKETCHA NJAFANG président de la CCCA, a rencontré Fabrice FUNIBA président de la TURCABA pour une première session d’échanges fructueux. Cette rencontre a permis d’explorer plusieurs axes de collaboration visant à renforcer les liens...",
        created_at: '2025-01-26',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 10,
        images: [actual8],
        type: 'interne',
        title: "𝗖𝗿é𝗲𝗿 𝗱𝗲𝘀 𝗹𝗶𝗲𝗻𝘀 𝘀𝗼𝗹𝗶𝗱𝗲𝘀 𝗱𝗮𝗻𝘀 𝗹𝗲 𝗱𝗼𝗺𝗮𝗶𝗻𝗲 𝗱𝗲 𝗹’é𝗱𝘂𝗰𝗮𝘁𝗶𝗼𝗻 𝗲𝘁 𝗹𝗮 𝗳𝗼𝗿𝗺𝗮𝘁𝗶𝗼𝗻 𝗰𝗼𝗻𝘁𝗶𝗻𝘂𝗲 𝗲𝗻𝘁𝗿𝗲 𝗹𝗲 𝗖𝗮𝗻𝗮𝗱𝗮 𝗲𝘁 𝗹𝗲 𝗖𝗮𝗺𝗲𝗿𝗼𝘂𝗻. 🌍🤝🇨🇲🇨🇦",
        description: "Notre Président Dr. Armand NGAKETCHA NJAFANG appuie la mission au Cameroun du Collège Boréal dans son développement d’affaires dans le secteur de l’éducation et la formation continue. Les membres du 𝗖𝗼𝗹𝗹è𝗴𝗲 𝗕𝗼𝗿é𝗮𝗹, acteur majeur de l'éducation francophone au Canada, ont eu l'opportunité de rencontrer des personnalités influentes et des institutions de renom au Cameroun.",
        created_at: '2025-01-26',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 9,
        images: [actual7],
        type: 'interne',
        title: "𝗨𝗻𝗲 𝘀𝗼𝗶𝗿é𝗲 𝗱'𝗲𝗰𝗵𝗮𝗻𝗴𝗲𝘀 𝗲𝗻 𝗽𝗿é𝗹𝘂𝗱𝗲 𝗮𝘂 𝗽𝗿𝗼𝗷𝗲𝘁 𝗱'𝗲𝘅𝗼𝗻é𝗿𝗮𝘁𝗶𝗼𝗻 𝗱𝗲𝘀 𝗱𝗿𝗼𝗶𝘁𝘀 𝗱𝗲 𝗱𝗼𝘂𝗮𝗻𝗲 𝗱𝗲𝘀 𝗽𝗿𝗼𝗱𝘂𝗶𝘁𝘀 𝗰𝗮𝗻𝗮𝗱𝗶𝗲𝗻𝘀 🌎🫱🏽‍🫲🏽",
        description: "Invité par la 𝗗𝗼𝘂𝗮𝗻𝗲 𝗖𝗮𝗺𝗲𝗿𝗼𝘂𝗻𝗮𝗶𝘀𝗲, 𝗹𝗲 président de la 𝗖𝗵𝗮𝗺𝗯𝗿𝗲 𝗱𝗲 𝗖𝗼𝗺𝗺𝗲𝗿𝗰𝗲 𝗖𝗮𝗻𝗮𝗱𝗮-𝗔𝗳𝗿𝗶𝗾𝘂𝗲 (𝗖𝗖𝗖𝗔) Dr. Armand NGAKETCHA NJAFANG a participé hier à 𝗹𝗮 𝗦𝗼𝗶𝗿é𝗲 𝗱𝗲𝘀 𝗣𝗮𝗿𝘁𝗲𝗻𝗮𝗶𝗿𝗲𝘀 à l'hôtel là Falaise de Bonanjo.",
        created_at: '2025-01-25',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 6,
        images: [actual4],
        type: 'interne',
        title: "𝗥𝗲𝗻𝗳𝗼𝗿𝗰𝗲𝗿 𝗹𝗲𝘀 𝗹𝗶𝗲𝗻𝘀 𝗲𝗻𝘁𝗿𝗲 𝗹𝗮 𝗳𝗿𝗮𝗻𝗰𝗼𝗽𝗵𝗼𝗻𝗶𝗲 𝗲𝘁 𝗹𝗲𝘀 𝗮𝗳𝗳𝗮𝗶𝗿𝗲𝘀 𝗲𝗻 𝗥𝗲́𝗽𝘂𝗯𝗹𝗶𝗾𝘂𝗲 𝗗𝗲́𝗺𝗼𝗰𝗿𝗮𝘁𝗶𝗾𝘂𝗲 𝗱𝘂 𝗖𝗼𝗻𝗴𝗼-𝗘𝘀𝘁 🌍🇨🇦🇨🇩",
        description: "M. charles mulanda, délégué pays de la Chambre de Commerce Canada Afrique en 𝗥𝗗𝗖-𝗘𝘀𝘁, a eu l'honneur de participer à une rencontre d'affaires enrichissante avec M. Léandre MIEMA, Délégué Général Permanent de la Francophonie et Correspondant National auprès de l'OIF/RDC.",
        created_at: '2025-01-22',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 7,
        images: [actual6],
        type: 'interne',
        title: "𝐑𝐞𝐧𝐟𝐨𝐫𝐜𝐞𝐦𝐞𝐧𝐭 𝐝𝐞𝐬 𝐫𝐞𝐥𝐚𝐭𝐢𝐨𝐧𝐬 𝐛𝐢𝐥𝐚𝐭é𝐫𝐚𝐥𝐞𝐬 𝐞𝐧𝐭𝐫𝐞 𝐥’𝐀𝐥𝐠é𝐫𝐢𝐞 𝐞𝐭 𝐥𝐞 𝐂𝐚𝐧𝐚𝐝𝐚 🌍",
        description: "Deux audiences marquantes ont eu lieu dans le cadre du développement des relations économiques entre l’Algérie et le Canada :1️⃣ 𝗔𝘂𝗱𝗶𝗲𝗻𝗰𝗲 𝗮𝘂 𝗺𝗶𝗻𝗶𝘀𝘁è𝗿𝗲 𝗱𝗲𝘀 𝗔𝗳𝗳𝗮𝗶𝗿𝗲𝘀 é𝘁𝗿𝗮𝗻𝗴è𝗿𝗲𝘀, 👉 𝐌. 𝐌𝐨𝐡𝐚𝐧𝐝 𝐎𝐮𝐫𝐚𝐦𝐝𝐚𝐧𝐞 𝐋𝐀𝐑𝐀𝐁, délégué de l'Algérie auprès de la Chambre de Commerce Canada-Afrique (CCCA), a échangé avec 𝐌. 𝐑𝐚𝐛𝐚𝐡 𝐅𝐚𝐬𝐢𝐡, Directeur de la Coopération et des Échanges Économiques.",
        created_at: '2025-01-18',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 8,
        images: [actual5],
        type: 'interne',
        title: "Un partenariat prometteur avec l’Agence Guinéenne de Promotion des Exportations (AGUIPEX)",
        description:"Ce jeudi 16 janvier 2025 à 18h30, Mr Robin Camara, Représentant Pays de la Chambre de Commerce Canada Afrique et son assistante Mme 𝗗𝗶𝗲𝗻𝗴 𝗦𝗲𝗹𝗹𝘆 𝗦𝗲𝗰𝗸, ont été reçus par M. Amadou D’affaires Baldé, Directeur Général de l' Agence Guinéenne de ...",
        created_at: '2025-01-18',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 12,
        images: [actual10],
        type: 'interne',
        title: "𝗖𝗢𝗠𝗠𝗨𝗡𝗜𝗖𝗔𝗧𝗜𝗢𝗡 𝗢𝗙𝗙𝗜𝗖𝗜𝗘𝗟𝗟𝗘 🇩🇿",
        description:"Audience du délégué Algérie de la Chambre de Commerce Canada Afrique (La CCCA). Ce matin, le délégué Algérie de la CCCA, M. 𝗠𝗢𝗛𝗔𝗡𝗗 RAMDANE LARAB, a été reçu par M. Kamel Hamenni, Président de la Chambre Algérienne de Commerce et d'Industrie CACI , accompagné de 𝗠. 𝗛𝗮𝗳𝗶𝗱 𝗦𝗮𝗶𝗺, Président de la 𝗖𝗵𝗮𝗺𝗯𝗿𝗲 𝗱𝗲 𝗖𝗼𝗺𝗺𝗲𝗿𝗰𝗲 𝗲𝘁 𝗱'𝗜𝗻𝗱𝘂𝘀𝘁𝗿𝗶𝗲 (𝗖𝗖𝗜) 𝗢𝘂𝗺 𝗘𝗹 𝗕𝗼𝘂𝗮𝗴𝗵𝗶.",
        created_at: '2025-01-07',
        video_url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    },
    {
        id: 1,
        images: [jambo],
        type: 'interne',
        title: t('news_title1'),
        description: t('news_description1'),
        created_at: '2024-09-21',
        video_url: vidJambo
    },
    // {
    //     id: 3,
    //     image: actual,
    //     type: 'externe',
    //     url: 'https://francopresse.ca/actualite/2024/10/07/exode-des-cerveaux-lafrique-se-vide-au-profit-du-canada/',
    //     titre: t('news_title2'),
    //     descrip: t('news_description2'),
    //     date: '2024-09-10',
    //     videoUrl: 'https://francopresse.ca/actualite/2024/10/07/exode-des-cerveaux-lafrique-se-vide-au-profit-du-canada/'
    // },
    // {
    //     id: 4,
    //     image: actual2,
    //     type: 'externe',
    //     url: 'https://ici.radio-canada.ca/nouvelle/2108988/recruter-infirmieres-afrique-ethique-caq-maroc-tunisie',
    //     titre: t('news_title3'),
    //     descrip: t('news_description3'),
    //     date: '2024-09-05',
    //     videoUrl: 'https://ici.radio-canada.ca/nouvelle/2108988/recruter-infirmieres-afrique-ethique-caq-maroc-tunisie'
    // },
    // {
    //     id: 5,
    //     image: actual3,
    //     type: 'externe',
    //     url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene',
    //     titre: t('news_title4'),
    //     descrip: t('news_description4'),
    //     date: '2024-09-01',
    //     videoUrl: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    // }
];
 


let globalData= [...Actualites,...data]
    
    const filteredData = globalData?.filter((item) => {
        const matchesSearchQuery = item.title.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesDate = selectedDate ? item.created_at === selectedDate : true;
        return matchesSearchQuery && matchesDate;
    });

   
    const totalItems = filteredData.length || 0;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const removeBrTags = (str) => {
        return str?.replace(/<br\s*\/?>/gi, ''); // Remplace toutes les balises <br> par une chaîne vide
      };

      let descripHeight=(x)=>{
        if(removeBrTags(x).length>255){
            if(x.includes('<br>')){
                return removeBrTags(x).slice(0,730)+' ...'
            }else{
                return removeBrTags(x).slice(0,245)+' ...'
            }
        }else{
            return removeBrTags(x)
        }
        // removeBrTags(x.description).length>255?removeBrTags(x.description).slice(0,555)+' ...':removeBrTags(x.description)
      }
    return (
        <Annimated>
            <Banner title={t('news_banner_title')} />
            <div className="px-[5%] w-full flex gap-[3rem] py-14 max-md:py-12">
                <div className='w-[73%] max-md:w-full'>
                    <div className='flex flex-col gap-5 pb-0'>
                        <div className='flex flex-col gap-4 md:flex-row'>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder={t('search_by_title')}
                                className="border-[1px] border-primary p-2 px-4 w-full md:w-[50%] outline-none rounded-md"
                            />
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                className="border-[1px] border-primary p-2 px-4 w-full md:w-[50%] outline-none rounded-md"
                            />
                        </div>
                        <span className='text-[.9rem] font-semibold'>{filteredData?.length} {t('results')}</span>
                    </div>
                    <div className='flex flex-col'>
                        {paginatedData?.map((x,index) => (
                            <a href={`/a/Actualites/${x.title}`} key={x.id} className='relative w-full flex max-md:flex-col py-5 flex-row-reverse gap-3 overflow-hidden transition-all duration-500 border-b-[1px] border-primary last-of-type:border-none cursor-pointer'>
                                <div className='w-[35%] max-md:h-[300px] max-h-[500px] relative max-md:w-full'>
                                    <img className={`absolute ${(x.id===15 || x.id===16)? 'object-contain': 'object-cover'} ${x.id===20 && 'object-top'} w-full h-full rounded-md`} src={x.images[0].toLowerCase().includes('uploads/') ? ` ${BaseUrl}/${x.images[0]}`:x.images[0] } alt={x.title} />
                                </div>
                                <div className='flex flex-col max-md:items-center max-md:p-1 gap-3 max-md:gap-3 w-[65%] max-md:w-full'>
                                    <h1 className='text-[1.6rem] hover:text-secondary transition-all duration-300 max-md:text-[1.1rem] max-md:text-center font-semibold text-primary' dangerouslySetInnerHTML={{__html:x.title}}></h1>
                                    <span className='text-[1.1rem] max-md:text-center max-md:text-[.92rem] font-normal' dangerouslySetInnerHTML={{__html:descripHeight(x.description)}}></span>
                                    <span className='text-[.85rem] max-md:text-[.85rem] max-sm:text-[.7rem] text-green-600 font-medium'> {timeRegular(moment().diff(x.created_at, "days"),moment(x.created_at),t)}</span>
                                </div>
                            </a>
                        ))}
                    </div>
                    <div className='flex items-center justify-center w-full pt-10'>
                        <ul className='flex gap-1 p-2'>
                            <li
                                className={`p-1 px-4 ${currentPage === 1 ? 'text-gray-400' : 'cursor-pointer'}`}
                                onClick={handlePreviousPage}
                            >
                                <ArrowLeft01Icon />
                            </li>
                            {Array?.from({ length: totalPages }).map((_, index) => (
                                <li
                                    key={index}
                                    className={`p-1 px-4 ${currentPage === index + 1 ? 'text-white bg-secondary' : 'bg-gray-300 cursor-pointer'}`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </li>
                            ))}
                            <li
                                className={`p-1 px-4 ${currentPage === totalPages ? 'text-gray-400' : 'cursor-pointer'}`}
                                onClick={handleNextPage}
                            >
                                <ArrowRight01Icon />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='w-[27%] max-md:hidden flex flex-col gap-8'>
                    <div className='bg-primary text-white flex flex-col gap-4 px-8 py-[1.5rem]'>
                        <h3 className='text-[1.3rem] font-medium'>{t('your_choices')}</h3>
                        <span className='text-[.85rem]'>{t('publications')}</span>
                        <button
                            className='border-[1px] border-white py-1 px-4 w-full'
                            onClick={() => {
                                setSearchQuery('');
                                setSelectedDate('');
                                setCurrentPage(1);
                            }}
                        >
                            {t('reset_choices')}
                        </button>
                    </div>
                    <Publicite />
                    <Infolettre />
                </div>
            </div>
            {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7292227895253426176" height="1264" width="504" frameborder="0" allowfullscreen="" title="Post intégré"></iframe> */}
        </Annimated>
    );
}
