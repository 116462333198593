// src/components/Sidebar.js
import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiBook,
  FiFileText,
  FiCalendar,
  FiFlag,
  FiMessageSquare,
  FiSettings,
  FiUsers,
  FiBriefcase,
  FiLogOut,
} from 'react-icons/fi';
import { MdClose, MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos, MdOutlineDoubleArrow } from 'react-icons/md';
import logo from '../../../assets/1x/icone blanc.png';
import { FaCross } from 'react-icons/fa';
import { ArrowRight01Icon } from 'hugeicons-react';
import $ from 'jquery'

function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  $(function () {
    let toggleValue = false;
    $(document).on('click', '.settings-btn', function () {
      toggleValue = !toggleValue
      const pixel = $(this).siblings().prop('scrollHeight');
      if (toggleValue) {
        $(this).siblings().css('height', `${pixel}px`);
        $(this).children().last().addClass('rotate-[90deg]');
      } else {
        $(this).siblings().css('height', `0px`);
        $(this).children().last().removeClass('rotate-[90deg]');
      }
    })
  })

  const menuItems = [
    {
      group:'',
      corps:[
        { title: 'Tableau de Bord', icon: <FiHome size={20}/>, path: '/Admin',children:[] },
        { title: 'Acceuil du site', icon: <FiHome size={20}/>, path: '/a',children:[] },
      ]
    },
    {
      group:'Pages',
      corps:[
        { title: 'CCCA Revue', icon: <FiBook size={20}/>, path: '/Admin/ccca-revue',children:[] },
        { title: 'Actualité', icon: <FiFileText size={20}/>, path: '/Admin/news',children:[] },
        { title: 'Événements', icon: <FiCalendar size={20} />, path: '/Admin/events',children:[] },
        { title: 'Activité', icon: <FiFlag size={20}/>, path: '/Admin/activities',children:[] },
        { title: 'Services', icon: <FiBriefcase size={20} />, path: '/Admin/Services',children:[] },
        { title: 'À propos', icon: <FiBriefcase size={20} />, path: '#',children:[
          { title: 'Presentation', path: "/Admin/à-propos/presentation" },
          { title: 'Conseil d\'administration', path: "/Admin/à-propos/Conseil-d'administration" },
          { title: 'Diretion Éxecutive', path: '/Admin/ConseilAdministration' },
          { title: 'Bureau Afrique', path: '/Admin/à-propos/bureau-afrique' },
          { title: 'Bureau éxécutive', path: '/Admin/ConseilAdministration' },
          { title: 'Galérie', path: '/Admin/à-propos/galéries' },
        ] },
      ]
    },
    {
      group:'Autre',
      corps:[
        { title: 'Utilisateurs', icon: <FiUsers size={20} />, path: '/Admin/Utilisateurs',children:[] },
        { title: 'Parténaires', icon: <FiUsers size={20} />, path: '/Admin/ConseilAdministration',children:[] },
        { title: 'Membership', icon: <FiUsers size={20} />, path: '/Admin/ConseilAdministration',children:[] },
        { title: 'Publicité', icon: <FiUsers size={20} />, path: '/Admin/publicite',children:[] },
        { title: 'Communiqué', icon: <FiUsers size={20} />, path: '/Admin/ConseilAdministration',children:[] },
      ]
    },
    {
      group:'Paramétre',
      corps:[
        { title: 'Paramètres', icon: <FiSettings />,path: '/Admin/ConseilAdministration',children:[] },
        { title: 'Déconnexion', icon: <FiLogOut />,path: '/Admin/ConseilAdministration',children:[] },
      ]
    },
  ];


  return (
      <div
        className={`fixed h-[100vh] left-0 side-ccca w-[20.5%] max-md:w-[65%] max-sm:w-[85%] bg-primary shadow-sm transform transition-transform duration-500`}
      >
        {/* Sidebar Header */}
        <div className="flex items-center justify-between py-[1.09rem] max-md:py-[.85rem] border-b border-white px-[7%]">
          <div className="flex items-center justify-center max-md:justify-start gap-2 w-full">
            <img src={logo} alt="Logo" className="w-9 max-md:w-7 max-md:h-7 h-9" />
            <div className='flex flex-col font-semibold text-[.95rem] max-md:text-[.8rem] text-white leading-[1.15rem]'>
              <span>Chambre de Commerce</span>
              <span>Canada Afrique</span>
              </div>
          </div>
          <div className='menu-close hidden max-md:block'><MdClose size={25} color='white'/></div>
        </div>
        {/* Sidebar Menu */}
        <div className="px-4 py-3 overflow-y-auto h-[calc(100vh-69px)]">
          <div className="sidebare-admin flex flex-col gap-4">
          {menuItems?.map((x, indexi) => (
            <div key={indexi}>
              <p className={`text-md font-medium text-white text-opacity-30 mb-1`}>{x.group}</p>
              <div className='flex flex-col gap-1'>
              {x?.corps.map((item, index) => (
                item.children.length<=0 ?
                <NavLink
                  key={index}
                  to={item.path}
                  className={`flex items-center nav-items gap-4 w-full px-5 py-3 text-white text-opacity-60 font-medium transition-all duration-200 hover:bg-white hover:text-opacity-100 hover:bg-opacity-20 rounded-md`}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <span className="text-xl">{item.icon}</span>
                  <span className="">{item.title}</span>
                </NavLink>:
                <div
                  key={index}
                  // to={item.path}
                  className={`flex cursor-pointer flex-col nav-items overflow-hidden w-full text-white text-opacity-60 font-medium rounded-md`}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <div className='settings-btn flex justify-between px-5 hover:text-white hover:bg-white hover:text-opacity-100 hover:bg-opacity-20 py-3 items-center'>
                    <div className='flex items-center gap-4'>
                      {item.icon}
                      <span className="">{item.title}</span>
                    </div>
                    <ArrowRight01Icon className='transition-all duration-200' /> 
                  </div>
                  <ul className='overflow-y-hidden transition-all flex flex-col gap-1 ps-7 duration-500 text-[1.02rem]' style={{height:0}}>
                    {item.children.map((y,inde)=>
                      <li key={inde} className='py-2'><NavLink className='flex hover:text-white hover:text-opacity-100 items-center gap-3' to={y?.path}><MdOutlineDoubleArrow /> {y?.title}</NavLink></li>
                    )}
                  </ul>
                </div>
              ))}
              </div>
            </div>
          ))}
          </div>

          {/* Account Section
          <div className="mt-2 space-y-1">
            {accountItems.map((item, index) => (
              <button
                key={index}
                className={`flex items-center gap-3 w-full px-4 py-4 text-gray-700 transition-all duration-200 hover:bg-gray-200 rounded-md`}
              >
                <span className="text-xl">{item.icon}</span>
                <span className="">{item.title}</span>
              </button>
            ))}
          </div> */}
        </div>
        
      </div>
  );
}

export default Sidebar;
