import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import './galerieActualite.css';

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { BaseUrl } from "../config";

export default function GallerieActualite({ image, id }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // const swiperRef = useRef(null);

  // const updateSwiper = (swiper) => {
  //   if (!swiper || typeof swiper !== 'object') {
  //     console.error("Swiper instance is undefined or null");
  //     return;
  //   }
    
  //   Object.assign(swiper, { autoplay: { delay: 3000 } });
  //   swiper.update();
  // };
  // useEffect(() => {
  //   if (swiperRef.current) {
  //     updateSwiper(swiperRef.current);
  //   }
  // }, []);

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff"
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper || null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {image?.map((x, index) => (
          <SwiperSlide key={index}>
            <img
              src={x.includes('uploads/') ? `${BaseUrl}/${x}`:x  }
              alt="#"
              title={x}
              className={`w-full h-[650px] max-md:h-[350px] object-cover ${
                id === 6 ? "object-top" : ""
              } rounded-md`}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {image?.length>1 &&
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {image?.map((x, index) => (
          <SwiperSlide key={index}>
            <img src={x.includes('uploads/') ? `${BaseUrl}/${x}`:x } alt={x} />
          </SwiperSlide>
        ))}
      </Swiper>
      }
    </>
  );
}
