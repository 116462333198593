// src/pages/Events.js
import React, { useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import EventList from "../Components/EventList";
// import $ from "jquery";
import Breads from "../Components/Breads";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvenement } from "../../../store/evenements.slice";

export default function Events() {
  const breadcrumbLinks = [
    { label: "Acceuil", path: "/" },
    { label: "Evenement", path: "/Admin/event" }
    // { label: "Product", path: "/product" },
  ];
  // const dispatch = useDispatch();
  // const { Evenements, isSuccess,isLoading } = useSelector((state) => state.evenements);
  // useEffect(() => {
  //   dispatch(getAllEvenement());
  // }, [dispatch]);

  // console.log(Evenements)
  // console.log(new Date().toISOString())
  
  return (
    <div className="flex flex-col gap-5">
       <Breads breadcrumbLinks={breadcrumbLinks} title="Évenements"/>
      <div className="p-[3%] bg-white flex flex-col gap-7 max-md:gap-3 rounded-lg">
        <div className="flex flex-col gap-3 component">
          <div className="flex items-center max-md:flex-col gap-2 justify-between banerpp">
            <h3 className="text-nowrap text-[1.4rem] max-md:text-[1.1rem]">
              Liste des évenement
            </h3>
            <div className="w-full flex max-md:grid max-md:grid-cols-3 text-center items-center gap-2 justify-end max-md:justify-center text-[.8rem]">
              <div className="bg-secondary cursor-pointer btn-filter text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                Filre
              </div>
              <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                pdf
              </div>
              <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                imprimer
              </div>
            </div>
          </div>
          <div
            className="border border-[#3174ad] rounded-md overflow-hidden bg-white filter-component overflow-y-hidden transition-all duration-500"  style={{height:0}}
          >
            <div className="flex flex-col">
              <div className="flex items-center justify-center bg-[#3174ad] text-white p-2">
                <span className="text-center text-[1.1rem] max-md:text-[.9rem] font-medium capitalize">
                  Filtre
                </span>
              </div>
              <div className="p-4 filter-component">
                <form className="grid grid-cols-3 max-md:grid-cols-1 gap-4">
                <input
                  type="text"
                  className="formulaire"
                  placeholder="Titre de l'evenement"
                ></input>
                <input
                  type="text"
                  className="formulaire"
                  placeholder="Lieux de l'evenement"
                ></input>
                <input
                  type="date"
                  className="formulaire"
                  placeholder="lieux"
                ></input>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <EventList h={555} event={Evenements}/> */}
      </div>
    </div>
  );
}

